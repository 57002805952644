
import { defineComponent, ref, computed, watch, onMounted, nextTick } from 'vue';
import {useRoute} from 'vue-router';
import BaseInput from '@/components/ui/BaseInput.vue';
import articles from "@/services/articles";
import brand from "@/services/brand";
import SmallSpinner from '@/components/SmallSpinner.vue';
import vehicleModels from "@/services/vehicleModels";
import vehicleBrands from "@/services/vehicleBrands";
import product from "@/services/product";
import vin from "@/services/vin";
import {Status, StatusType} from "@/components/types/enums";
import vehicles from '@/services/vehicles';
import certificates from '@/services/certificates';
import systemProductGroups from '@/services/systemProductGroups';
import { ArticleModel } from '@/models/ArticleExportModel';

export default defineComponent({
  name: 'SearchDropdown',
  emits: ['update'],
  components: {
    BaseInput,
    SmallSpinner
  },
  props: {
    saveChosenItem: Boolean,
    productGroupId: {
      type: Number,
      default: () => null
    },
    subGroupId: {
      type: Number,
      default: () => null
    },
    type: {
      type: String,
      required: false,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    description: {
      type: Boolean,
      default: () => false
    },
    // Для поиска брендов (CM / OE)
    brand: {
      type: String,
      default: () => ''
    },
    // Для поиска брендов (CM / OE)
    blocked: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: String,
      default: () => ''
    },
    options: {
      type: Array,
      default: () => []
    },
    noDelay: {
      type: Boolean,
      default: () => false
    },
    height: {
      type: String,
      default: () => 's'
    },
    outsideShowResult: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const route = useRoute();
    const searchText = ref('' as string);
    const searchResult = ref([] as Array<object>);
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const showSearchResult = ref(false as boolean);
    const firstTimeSearch = ref(2 as Number);
    const error = ref('' as string);
    const baseInput = ref({} as any);
    const isLoading = ref(false as boolean);
    const getName = computed(() => {
      return (item: any) => {
        if (props.type === 'ARTICLES_BY_PRODUCT_GROUP') return item && item.article
        if (
          props.type === 'PRODUCT_GROUP' ||
          props.type === 'PRODUCT_SUB_GROUP'
        ) return item.desc;
        if (
          props.type === 'CERTIFICATE_PRODUCT_GROUP' ||
          props.type === 'CERTIFICATE_PRODUCT_SUB_GROUP' ||
          props.type === 'CERTIFICATED_PRODUCT_GROUPS' ||
          props.type === 'SYSTEM' ||
          props.type === 'CERTIFICATE_SUB_GROUPS' ||
          props.type === 'PRODUCT_SUB_SUB_GROUP' ||
          props.type === 'CERTIFICATE_DECLARATIONS'
        ) {
          return item && item.name
        }
        if (props.type === 'CERTIFICATE_BRAND') {
          return item.brand
        }
        if (props.type === 'TEC_DOC_PG') return `${item.desc} (id: ${item.tecdocId})`
        if (props.type === 'ARTICLE') {
          if (item.isIncludeIn) {
            return item.includeInArticle
          }

          return item.article
        }
        if (props.type === 'BRAND' || props.type === 'CM_OE_BRANDS' || props.type === 'VEHICLE_BRAND') return item.brand;
        if (props.type === 'VB_TD') {
          if (item && item.brand) {
            return item.brand
          }

          return `${item.model.brand.brand} ${item.model.model} ${item.desc.modBodyNum ? `(${item.desc.modBodyNum})` : ''}`
        }
        if (props.type === 'VEHICLE_MODEL') return item.model;
        if (props.type === 'ENGINE_VALUE') return item;
        if (props.type === 'FUEL_TYPE') return item;
        if (props.type === 'VIN') return item && item.article;

        return item
      }
    });
    const getDescription = computed(() => {
      return (item: any) => {
        if (!item.productGroup || !item.productGroup.desc) {
        }
        if (props.type === 'ARTICLE') return item.productGroup ? `ТГ: ${item.productGroup}` : 'Товарная группа не указана'
      }
    });
    const foundBy = computed(() => {
      return (item: any) => {
        switch (item.type) {
          case 'CODE':
            return 'Нашло по коду'
          case 'ARTICLE':
            return 'Нашло по артикулу'
          case 'CROSS':
            return 'Нашло по кроссу'
          case 'NAME':
            return 'Нашло по наименованию'
          default:
            return ''
        }
      }
    })

    const getStatusArticle = computed(() => {
      return (item: any) => {
        if (item && item.status) {
          return `Статус: ${Status[item.status as StatusType]}`
        }
      }
    })

    watch(() => props.text, (val) => {
      // if (
      //   props.type === 'PRODUCT_GROUP' ||
      //   props.type === 'PRODUCT_SUB_GROUP' ||
      //   props.type === 'PRODUCT_SUB_SUB_GROUP' ||
      //   props.type === 'ARTICLE'
      // ) {
      //   if (!(props.text && props.text.length)) {
      //     baseInput.value.clearInput()
      //   } else {
      //     searchText.value = val
      //   }
      // } else {
        searchText.value = val
      // }
    });

    watch(() => props.brand, async () => {
      await search(searchText.value ? searchText.value : '')
    });

    function clickOutside(e: any) {
      if (document.getElementById(`search-${props.type}-${getRandomId.value}`)) {
        showSearchResult.value = document.getElementById(`search-${props.type}-${getRandomId.value}`)!.contains(e.target)
      }
    }

    async function search(item: string) {
      searchText.value = item;
      error.value = '';
      searchResult.value = [];
      if ((item && item.length > 1) || props.noDelay) {
        try {
          let result = [] as any;

          if (!props.type) {
            result = props.options.filter((option: any) => {
              const optionToString = String(option)
              return optionToString.toLowerCase().includes(item.toLowerCase().trim())
            })

            emit('update', item)
          }

          if (props.type === 'SYSTEM') {
            let tempResult = await systemProductGroups.getSystems()

            tempResult = tempResult.filter((system: any) => {
              return system.name.toLowerCase().includes(item.toLowerCase().trim())
            })
            result = tempResult
          }
          if (props.type === 'ARTICLES_BY_PRODUCT_GROUP') {
            isLoading.value = true;
            let tempResult = await articles.findAllByProductGroup(props.productGroupId);
            result = item && item.length
              ? sortArticles(tempResult, item)
              : tempResult && tempResult.length
                  ? tempResult.sort(articlesSort)
                  : []
          }
          if (props.type === 'VIN') {
            isLoading.value = true;
            result = await vin.getArticlesByVin(item);
            result = result.map((o: any) => o.article)
            isLoading.value = false;
          }
          if (props.type === 'ARTICLE') {
            isLoading.value = true;
            const allArticleByName = await articles.newFindArticleByName(item);
            const transformedData = [] as any

            allArticleByName.map((article: any, index: any) => {
              const isPairExist = !!(article && article.pair && article.pair.length)
              const isIncludeInExist = !!(article && article.includeIn && article.includeIn.length)
              const isConsistsOfExist = !!(article && article.consistsOf && article.consistsOf.length)

              transformedData.push(article)

              if (isPairExist) {
                article.pair.map((articlePair: any) => {
                  transformedData.splice(transformedData.length, 0, {
                    ...articlePair,
                    isPair: true,
                    isIncludeIn: false,
                    isConsistsOf: false,
                    pairInArticle: article.article
                  })
                })
              }

              if (isIncludeInExist) {
                article.includeIn.map((articleIncludeIn: any) => {
                  transformedData.splice(transformedData.length, 0, {
                    ...articleIncludeIn,
                    includeInArticle: article.article,
                    isPair: false,
                    isIncludeIn: true,
                    isConsistsOf: false
                  })
                })
              }

              if (isConsistsOfExist) {
                article.consistsOf.map((articleConsistsOf: any) => {
                  transformedData.splice(transformedData.length, 0, {
                    ...articleConsistsOf,
                    consistsOfArticle: article.article,
                    isPair: false,
                    isIncludeIn: false,
                    isConsistsOf: true
                  })
                })
              }
            })

            result = transformedData
            // result = result.map((o: any) => o.article)
          }
          if (props.type === 'CERTIFICATE_BRAND') {
            result = await certificates.fetchCertificationBrands()
          }
          if (props.type === 'CERTIFICATE_SUB_GROUPS') {
            const productSubGroups = await certificates.listProductSubGroups()

            if (item && item.length) {
              result = productSubGroups.filter((psg: any) => {
                return psg && psg.name.toLowerCase().includes(item.toLowerCase())
              })
            } else {
              result = productSubGroups
            }
          }
          if (props.type === 'CERTIFICATE_PRODUCT_GROUP') {
            const productGroups = await certificates.searchProductGroupsByRequests();

            if (item && item.length) {
              result = productGroups.filter((pg: any) => pg && pg.name.toLowerCase().includes(item.toLowerCase()))
            } else {
              result = productGroups
            }
          }
          if (
            props.type === 'CERTIFICATE_PRODUCT_SUB_GROUP' ||
            props.type === 'CERTIFICATED_PRODUCT_SUB_GROUP'
          ) {
            if (item && item.length) {
              result = props.options.filter((psg: any) => psg && psg.name.toLowerCase().includes(item.toLowerCase()))
            } else {
              result = props.options
            }
          }
          if (props.type === 'CERTIFICATED_PRODUCT_GROUPS') {
            const certificatedProductGroups = await certificates.searchProductGroupsByCertificates();

            if (item && item.length) {
              result = certificatedProductGroups.filter((certificatedPg: any) => certificatedPg && certificatedPg.name.toLowerCase().includes(item.toLowerCase()))
            } else {
              result = certificatedProductGroups
            }
          }
          if (props.type === 'PRODUCT_GROUP') {
            let tempResult = await product.findProductGroupByName(item ? item : '');
            result = item && item.length ? sortGroups(tempResult.content, item) : tempResult.content && tempResult.content.length ? tempResult.content.sort(productGroupSort) : []
          }
          if (props.type === 'TEC_DOC_PG') {
            let tempResult = await product.findByDescEn(item ? item : '');
            result = item && item.length
              ? sortGroups(tempResult, item)
              : tempResult && tempResult.length
                ? tempResult.sort(productGroupSort)
                : []
          }
          if (props.type === 'PRODUCT_SUB_GROUP') {
            if(props.subGroupId) {
              if (!item) {
                let tempResult = await product.findProductSubGroupByNameAndProductId('', props.subGroupId);
                result = tempResult.content && tempResult.content.length ? tempResult.content.sort(productGroupSort) : []
              } else {
                let tempResult = await product.findProductSubGroupByNameAndProductId(item, props.subGroupId);
                result = item.length ? sortGroups(tempResult.content, item) : tempResult.content && tempResult.content.length ? tempResult.content.sort(productGroupSort) : []
              }
            }
          }
          if (props.type === 'PRODUCT_SUB_SUB_GROUP') {
            if (props.subGroupId) {
              if (!item) {
                let tempResult = await product.findProductSubSubGroupByNameAndProductId('', props.subGroupId);
                result = tempResult.content && tempResult.content.length ? tempResult.content.sort(productGroupSort) : []
              } else {
                let tempResult = await product.findProductSubSubGroupByNameAndProductId(item, props.subGroupId);
                result = item.length ? sortGroups(tempResult.content, item, 'name') : tempResult.content && tempResult.content.length ? tempResult.content.sort(productGroupSort) : []
              }
            }
          }
          if (props.type === 'CM_OE_BRANDS') {
            const brands = await brand.findByPrefixCmAndOeBrand(item)
            result = item.length ? sortBrands(brands, item) : brands.sort(brandNameSort)
          }
          if (props.type === 'BRAND') {
            const brands = await brand.findByPrefix(props.brand, item)
            let tempResult = brands.content
            // let tempResult = await brand.findBrandsByPrefix(props.brand, item);
            result = item.length ? sortBrands(tempResult, item) : tempResult.sort(brandNameSort)
          }
          if (props.type === 'VEHICLE_BRAND') {
            result = await findVehicle(item)
          }
          if (props.type === 'VB_TD') {
            const isNumber = typeof +item === 'number' && !isNaN(+item)

            if (isNumber && item.length) {
              result = [await vehicles.findByTecdocId(item)]
            } else {
              result = await findVehicle(item)
            }
          }
          if (props.type === 'VEHICLE_MODEL' && props.brand) {
            let tempResult = await vehicleModels.getModelsByBrandIdAndBlocking(props.brand).then(res => {
              if (props.model && res.some((o: any) => o.id === Number(props.model))) {
                setInput(res.find((o: any) => Number(o.id) === Number(props.model)));
              }
              return res.filter((o: any) => o.model.toLowerCase().includes(item.toLowerCase()))
            });

            tempResult = tempResult.reduce((acc: any, element: any) => {
              if (acc.findIndex((e: any) => e.model === element.model) < 0) {
                acc.push(element)
              }
              return acc
            }, [])

            result = item.length ? tempResult : tempResult.sort(brandModelSort)
          }
          if ((props.type === 'ENGINE_VALUE' || props.type === 'FUEL_TYPE') && props.brand) {
            let tempResult = props.options.filter((o: any) => o && o.toLowerCase().includes(item.toLowerCase()))
            result = tempResult
          } else if ((props.type === 'ENGINE_VALUE' || props.type === 'FUEL_TYPE') && !props.brand) {
            result = props.options
          }
          if (!showSearchResult.value && (!firstTimeSearch.value && props.type === 'VEHICLE_BRAND')) showSearchResult.value = true;
          if (!!result.length) searchResult.value = result;
          if (!result.length) {
            error.value = 'Данные не найдены'
          }
        } catch (error) {
          error.value = 'Ошибка сервера';
          throw error
        }

        isLoading.value = false;

        if ((!item || !item.length) && ((props.type !== 'VEHICLE_BRAND' && !props.brand) || props.type === 'VEHICLE_MODEL' || props.type === 'ENGINE_VALUE' || props.type === 'FUEL_TYPE')) {
          clearInput()
        }
      } else if (!item || !item.length) {
        clearInput()
      }
    }
    function sortArticles(arr: any, str: string, sortByField = 'article') {
      let res: any[] = [];
      let arr2 = arr.map((el: any) => el[sortByField]?.toLowerCase().indexOf(str.toLowerCase()));
      let set = new Set(arr2);
      let sortedSet = [...set].sort(function (a: any, b: any) {
        return a - b;
      });
      sortedSet.forEach(el => {
        let tempArr = arr.filter((e: any) => {
          return e[sortByField]?.toLowerCase().indexOf(str.toLowerCase()) === el
        });

        res = tempArr.sort((a: any, b: any) => {
          if (a[sortByField]?.toLowerCase() < b[sortByField]?.toLowerCase()) {
            return -1;
          }
          if (a[sortByField]?.toLowerCase() > b[sortByField]?.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      });

      return res
    }

    function sortGroups(arr: any, str: string, sortByField = 'desc') {
      let res: any[] = [];
      // Массив всех индексов вхождений

      let arr2 = arr.map((el: any) => el[sortByField]?.toLowerCase().indexOf(str.toLowerCase()));
      // Массив уникальных вхождений
      let set = new Set(arr2);

      // Отсортированный массив уникальных вхождений
      let sortedSet = [...set].sort(function (a: any, b: any) {
        return a - b;
      });
      // Формирование списка, сначала подстрока на 0 месте, потом на 1 и т.д.
      sortedSet.forEach(el => {
        let tempArr = arr.filter((e: any) => {
          return e[sortByField]?.toLowerCase().indexOf(str.toLowerCase()) === el
        });

        res = res.concat(tempArr.sort((a: any, b: any) => {
          if (a[sortByField]?.toLowerCase() < b[sortByField]?.toLowerCase()) {
            return -1;
          }
          if (a[sortByField]?.toLowerCase() > b[sortByField]?.toLowerCase()) {
            return 1;
          }
          return 0;
        }))
      });

      return res
    }

    function sortBrands(arr: any, str: string) {
      let res: any[] = [];
      // Массив всех индексов вхождений
      let arr2 = arr.map((el: any) => el.brand.toLowerCase().indexOf(str.toLowerCase()));
      // Массив уникальных вхождений
      let set = new Set(arr2);
      // Отсортированный массив уникальных вхождений
      let sortedSet = [...set].sort(function (a: any, b: any) {
        return a - b;
      });
      // Формирование списка, сначала подстрока на 0 месте, потом на 1 и т.д.
      sortedSet.forEach(el => {
        let tempArr = arr.filter((e: any) =>
          e.brand.toLowerCase().indexOf(str.toLowerCase()) === el
        );
        res = res.concat(tempArr.sort(brandNameSort))
      });
      return res
    }

    function productGroupSort(a: any, b: any) {
      if (a && a.desc && a.desc.toLowerCase() < b && b.desc && b.desc.toLowerCase()) {
        return -1;
      }
      if (a && a.desc && a.desc.toLowerCase() > b && b.desc && b.desc.toLowerCase()) {
        return 1;
      }
      return 0;
    }

    function articlesSort(a: any, b: any) {
      if (a.article.toLowerCase() < b.article.toLowerCase()) {
        return -1;
      }
      if (a.article.toLowerCase() > b.article.toLowerCase()) {
        return 1;
      }
      return 0;
    }

    function brandNameSort(a: any, b: any) {
      if (a.brand.toLowerCase() < b.brand.toLowerCase()) {
        return -1;
      }
      if (a.brand.toLowerCase() > b.brand.toLowerCase()) {
        return 1;
      }
      return 0;
    }

    function brandModelSort(a: any, b: any) {
      if (a.modelFull.toLowerCase() < b.modelFull.toLowerCase()) {
        return -1;
      }
      if (a.modelFull.toLowerCase() > b.modelFull.toLowerCase()) {
        return 1;
      }
      return 0;
    }

    function clearInput() {
      emit('update', '')
      localStorage.removeItem(`${props.type}_FILTER_ITEM`)
    }

    function setInput(item: object) {
      showSearchResult.value = false;
      emit('update', item)
      console.log('Updated???')
      if (props.saveChosenItem) {
        localStorage.setItem(`${props.type}_FILTER_ITEM`, JSON.stringify(item))
      }
    }

    async function findVehicle(item: any) {
      const tempResult = await vehicleBrands.findByBrandIgnoreCaseContaining(item, props.blocked);

      if (props.brand && tempResult.some((pr: any) => pr.id === Number(props.brand))) {
        setInput(tempResult.find((pr: any) => Number(pr.id) === Number(props.brand)));
      }

      return item.length
          ? sortBrands(tempResult, item)
          : tempResult.sort(brandNameSort)
    }

    function setDefaultData() {
      searchText.value = props.text;
      document.addEventListener('click', clickOutside, false)
    }
    function isPair(item: any) {
      return item && item.isPair
        ? `Парный артикул ${item.pairInArticle}`
        : null
    }

    function isIncludeIn(item: any) {
      return item && item.isIncludeIn
        ? `Входит в ${item.article}`
        : null
    }

    function isConsistsOf(item: any) {
      return item && item.isConsistsOf
        ? `Состоит из ${item.article}`
        : null
    }

    function setSavedFilterItems() {
      const savedFilterItem = localStorage.getItem(`${props.type}_FILTER_ITEM`)

      if (savedFilterItem && props.saveChosenItem) {
        if (props.type === 'ARTICLE') {
          const filterItem = JSON.parse(localStorage.getItem(`${props.type}_FILTER_ITEM`) as string)
          searchText.value = filterItem.article
          setInput(filterItem)
        }

        if (
          props.type === 'PRODUCT_GROUP' ||
          props.type === 'PRODUCT_SUB_GROUP' ||
          props.type === 'PRODUCT_SUB_SUB_GROUP'
        ) {
          const filterItem = JSON.parse(localStorage.getItem(`${props.type}_FILTER_ITEM`) as string)

          nextTick(() => {
            searchText.value = props.type === 'PRODUCT_SUB_SUB_GROUP'
              ? filterItem.name
              : filterItem.desc

            setInput(filterItem)
          })
        }
        // editedItem.value = JSON.parse(savedFilterItem)
        // pass key of editedItem (property name) for further search
        // emit('update', Object.keys(editedItem.value)[0])
      }
    }

    onMounted(() => {
      setDefaultData()
      setSavedFilterItems()
    });

    return {
      isConsistsOf,
      isIncludeIn,
      isPair,
      searchText,
      searchResult,
      showSearchResult,
      error,
      getName,
      getDescription,
      baseInput,
      firstTimeSearch,
      isLoading,
      search,
      setInput,
      getStatusArticle,
      foundBy,
      getRandomId
    }
  },
  mounted() {
    if (this.noDelay && this.type === 'BRAND') this.search('')
  }
})
